var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: !0
});

var e = function (t) {
  if ("undefined" == typeof document) return 0;

  if (document.body && (!document.readyState || "loading" !== document.readyState)) {
    if (!0 !== t && "number" == typeof e.__cache) return e.__cache;
    var o = document.createElement("div"),
        d = o.style;
    d.display = "block", d.position = "absolute", d.width = "100px", d.height = "100px", d.left = "-999px", d.top = "-999px", d.overflow = "scroll", document.body.insertBefore(o, null);
    var r = o.clientWidth;
    if (0 !== r) return e.__cache = 100 - r, document.body.removeChild(o), e.__cache;
    document.body.removeChild(o);
  }
};

exports.scrollbarWidth = e;
export default exports;
export const __esModule = exports.__esModule,
      scrollbarWidth = exports.scrollbarWidth;